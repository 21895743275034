
$kendo-scrollview-bg: #0b1e25 !default;
$kendo-scrollview-navigation-bg:$primary !default;
$scrollview-navigation-border-radius:$kendo-border-radius-md !default;
$scrollview-navigation-width: 38px !default;
$scrollview-navigation-height: 38px !default;
$scrollview-navigation-icon-right: url(../../../../images/next.svg) !default;
$scrollview-navigation-icon-left: url(../../../../images/previous.svg) !default;
$scrollview-navigation-font-size: 1em !default;
$scrollview-navigation-margin-top: map-get( $spacing, 6 ) !default;
$scrollview-navigation-margin-left: map-get($spacing, 2) !default;
$scrollview-navigation-padding: map-get( $spacing, 2 ) !default;
$scrollview-navigation-box-sizing: border-box !default;
$scrollview-navigation-flex: flex !default;
$scrollview-navigation-row: row !default;
$scrollview-navigation-center: center !default;