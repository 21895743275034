// Drawer
$kendo-drawer-bg: $component-bg !default;
$kendo-drawer-text: $primary !default;
// $kendo-drawer-border: $component-border !default;
$kendo-drawer-border-width: 0 !default;
// $kendo-drawer-font-family: $kendo-font-family !default;
// $kendo-drawer-font-size: $kendo-font-size !default;
// $kendo-drawer-line-height: $kendo-line-height !default;
// $kendo-drawer-content-padding-x: $padding-x !default;
// $kendo-drawer-content-padding-y: $padding-y !default;
// $kendo-drawer-item-level-padding-x: map-get( $spacing, 4 ) !default;

// $kendo-drawer-item-level-count: 5 !default;

// $kendo-drawer-scrollbar-width: 7px !default;
// $kendo-drawer-scrollbar-color: rgba(156, 156, 156, .7) !default;
// $kendo-drawer-scrollbar-bg: #dedede !default;
// $kendo-drawer-scrollbar-radius: 20px !default;
// $kendo-drawer-scrollbar-hovered-color: rgba(156, 156, 156, 1) !default;

$kendo-drawer-item-padding-x: map-get( $spacing, 2 ) + map-get( $spacing, thin ) !default;
$kendo-drawer-item-padding-y: map-get( $spacing, 2 ) + map-get( $spacing, thin ) !default;
$kendo-drawer-item-font-size: $kendo-font-size !default;
$kendo-drawer-item-line-height: $kendo-line-height !default;
// $kendo-drawer-icon-size: $icon-size !default;

// $kendo-drawer-mini-initial-width: calc(4 * #{$kendo-drawer-item-padding-x} + #{$kendo-drawer-icon-size}) !default;

$kendo-drawer-hovered-bg: rgba($component-bg, 0.04) !default;
$kendo-drawer-hovered-text: $component-text-white !default;

// $kendo-drawer-focused-bg: $kendo-drawer-bg !default;
// $kendo-drawer-focused-shadow: $kendo-list-item-focus-shadow !default;

// $kendo-drawer-selected-bg: $selected-bg !default;
// $kendo-drawer-selected-text: $selected-text !default;
// $kendo-drawer-selected-hover-bg: $selected-hover-bg !default;
// $kendo-drawer-selected-hover-text: $selected-hover-text !default;



$drawer-mini-bg: $primary !default;
$drawer-mini-text: $white !default;

// Custom introduced variables for primary theme

$drawer-bg-primary: #FFFFFF !default;
$drawer-text-primary: $primary !default;
$drawer-hovered-text-primary: $kendo-drawer-hovered-text !default;
$drawer-hover-bg-primary: $primary  !default;
$drawer-selected-bg-primary: $drawer-bg-primary !default;
$drawer-focused-bg-primary: $drawer-hover-bg-primary !default;
$drawer-selected-text-primary: $drawer-text-primary !default;
$drawer-selected-hover-bg-primary: $drawer-hover-bg-primary !default;
$drawer-selected-hover-text-primary: $drawer-hovered-text-primary !default;