.k-drawer {
  box-shadow: $drawer-box-shadow;
}

.k-drawer-mini .k-drawer {
  background-color: $drawer-mini-bg;
  color: $drawer-mini-text;
}

.k-drawer-item {
    height: map-get($spacing, 9);
  &.k-level-0 {
    font-weight: $kendo-font-weight-bold;
  }
}

.k-drawer-container {
  height: 100%;
}

.k-drawer-content {
  background: $page-bg;
}

.k-drawer-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.k-drawer-items {
  flex: 1;
  overflow-y: auto;
}

.k-drawer-item .k-item-text {
  white-space: initial;
  overflow: initial;
  text-overflow: initial;

}
.k-drawer-item.k-selected {
  background: $drawer-bg-primary;
  color: $drawer-text-primary;
}
// Primary Theme(custom implemented)
.k-drawer-primary {
    @include wFill(
      $drawer-text-primary,
      $drawer-bg-primary
    );
    
    .k-drawer-wrapper {
    }
    .k-drawer{
      box-shadow: none;
    }
  .k-drawer-item {
    padding-left: map-get($spacing, 8);

    &:hover {
      color: $drawer-hovered-text-primary;
      background: $drawer-hover-bg-primary;
    }
  }
  } 
  //** Mini Drawer Related Changes
  .clickable {
    cursor: pointer;
  }
  
  .drawerPanel {
    div {
      width:  map-get($spacing, 10);
      height: map-get($spacing, 9);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    div:hover {
      background: rgba(0, 121, 193, 0.12);
      border-radius: map-get($spacing, 10);
    }
  }